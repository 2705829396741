.header {
	display: flex;
	align-items: center;
	height: 95px;
	justify-content: space-between;
	padding: 1rem;
	position: fixed;
	top: 0;
	width: 100%;
	background-color: black;
	z-index: 10000;
}

.nav-menu {
	display: flex;
}

.nav-menu li {
	padding: 0 1rem;
}

.nav-menu a {
	font-size: 1.2rem;
	font-weight: 500;
}

.nav-btns {
	transition: all 0.4s;
}
.nav-btns:hover {
	transform: skewY(5deg) skewX(15deg) scale(1.1);
	text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

.hamburger {
	display: none;
}

@media screen and (max-width: 1240px) {
	.hamburger {
		display: block;
		cursor: pointer;
	}
	.nav-menu {
		position: absolute;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		top: 0;
		left: -100%;
		text-align: center;
		width: 100%;
		height: 100vh;
		transition: 0.3s;
		z-index: -1;
		background: rgba(0, 0, 0, 0.9);
	}
	.nav-menu.active {
		left: 0;
	}
	.nav-menu li {
		padding: 1rem 0;
	}
	.nav-menu a {
		font-size: 2rem;
	}
}
