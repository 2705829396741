.pricing {
	width: 100%;

	padding: 4rem 0rem;
	background-color: rgba(0, 0, 0, 0.5);
}

.hero {
	height: 100vh;
	width: 100%;
	object-fit: contain;
}

h2 {
	text-align: center;
	padding: 2rem;
}
.card {
	padding: 4.5rem;
}

.card-container {
	max-width: 1140px;
	margin: auto;
	display: grid;
	grid-gap: 40px;
	//grid-template-columns: repeat(3, 1fr);
}

.card-container .card {
	border: 1px solid #eee;
	color: #eee;
}

.card:hover {
	background-color: #141414;
}

.card h3 {
	font-size: 1.4rem;
	padding: 1rem;
	text-align: center;
}

.card .bar {
	border-bottom: 1px solid #eee;
	width: 10%;
	margin: 1.5rem auto;
	display: block;
}

.card .btc {
	font-size: 3rem;
	font-weight: 600;
	padding: 1rem;
	text-align: center;
}

.pricing p {
	color: #eee;
	padding: 1rem 1rem;
	font-size: 1.2rem;
	line-height: 2rem;
}
.features {
	padding: 1rem 1rem;
	font-size: 1.2rem;
	line-height: 2rem;
	li {
		padding: 0.4rem;
		list-style: disc;
	}
}

.card .btn {
	display: block;
	width: 80%;
	margin: 2rem auto;
	text-align: center;
}
.btc {
	display: block;
	width: 80%;
	margin: 0 auto;
	text-align: center;
	line-height: 3rem;
}
.ampsbg {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}

@media screen and (max-width: 980px) {
	.card-container {
		grid-template-columns: 1fr;
	}
	.card {
		padding: 2rem;
	}
}
