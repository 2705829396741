// src/App.scss
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html,
body,
#root {
	height: 100%;
	margin: 0;
}

body {
	font-family: Arial, sans-serif;
	background-color: #f4f4f4;
	color: #333;
}
.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh; // Ensure the app takes the full viewport height
}

.container {
	width: 80%;
	margin: 0 auto;
}

header,
footer {
	background-color: #333;
	color: #fff;
	padding: 1rem 0;
}

header h1,
footer p {
	text-align: center;
}

nav ul {
	list-style: none;
	display: flex;
	justify-content: center;
	gap: 1rem;
}

nav ul li a {
	color: #fff;
	text-decoration: none;
}

main {
	flex: 1;
	padding: 2rem 0;
}
