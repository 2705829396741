.contact {
	margin-top: 1.5rem;
	padding-top: 1rem;
	img {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
	}
}

.email-form-container {
	background-color: #000;
	color: #fff;
	padding: 20px;
	border-radius: 10px;
	max-width: 800px;
	margin: 7rem auto;

	h1 {
		margin-bottom: 1rem;
	}
	input[type="text"],
	input[type="email"] {
		font-size: 1.5rem;
		height: 3rem !important;
	}
	textarea {
		height: 7rem;
	}

	form {
		display: flex;
		flex-direction: column;

		label {
			margin-top: 15px;
			margin-bottom: 15px;
			font-size: 1.1rem;

			input,
			textarea {
				width: 100%;
				padding: 10px;
				margin-top: 10px;
				margin-bottom: 1rem;
				border: 1px solid #ccc;
				border-radius: 5px;
				background-color: #222;
				color: #fff;
			}

			input::placeholder,
			textarea::placeholder {
				color: #888;
			}
		}

		button {
			padding: 10px 20px;
			background-color: #333;
			color: #fff;
			border: none;
			border-radius: 5px;
			cursor: pointer;
			font-size: 1.1rem;
			margin-top: 1rem;

			&:hover {
				background-color: #555;
			}
		}
	}
}
