#video {
	position: fixed;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}
img.mobilebg {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}

.hero {
	height: 100vh;
	width: 100%;
	object-fit: contain;
}

.hero .content {
	text-align: center;
	position: absolute;
	margin: auto;
	width: 100%;
	height: auto;
	top: 40vh;
}

.content h1 {
	font-size: 4rem;
	color: white;
	padding-bottom: 1rem;
}

.hero p {
	font-size: 1.8rem;
	font-weight: 200;
	text-transform: uppercase;
	margin-bottom: 1.6rem;
	padding-bottom: 1rem;
}

.hero .btn {
	margin: 1rem 0.2rem;
}

.heading-secondary {
	font-size: 3.5rem;
	text-transform: uppercase;
	font-weight: 700;
	display: inline-block;
	-webkit-background-clip: text;
	color: transparent;
	margin: 0 auto;
	letter-spacing: 0.5rem;
	transition: all 0.4s;
}
.heading-secondary:hover {
	letter-spacing: 0.6rem;
	transform: skewY(5deg) skewX(15deg) scale(1.1);
	text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 640px) {
	.content h1 {
		font-size: 3rem;
		padding-bottom: 1rem;
	}
	.content p {
		font-size: 1.4rem;
		margin-bottom: 1.6rem;
		padding-bottom: 1rem;
	}
	.hero .content {
		top: 30vh;
	}

	video {
		display: none;

		opacity: 0;
		z-index: -1000 !important;
	}
	img.mobilebg {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
	}
}
